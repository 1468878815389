//Inspiration: https://github.com/madsstorgaardnielsen/group5-proj/blob/master/fbcmanager-frontend/src/components/login_component/LoginPopup.jsx, visited 20-10-23.

.modalBox {
  background-color: rgb(255, 255, 255);
  padding: 60px 60px;
  padding-bottom: 90px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.form-containerspacing {
  padding: 10px;
}

.loginButton {
  padding: 10px;
  float: right;
}
