.navbar-custom {
  padding: 15px 0.5rem 1rem;
  text-align: left;
}

.container-fluid {
  margin-top: 2rem;
  padding: 15px;
}

.image150x150 {
  max-width: 150px;
  max-height: 150px;
}
