// Inspiration: https://bootstrapshuffle.com/classes/cards/card-title, visited 20-10-23.

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.btn {
  margin-right: 1rem; // margin between buttons
}

.header-container {
  position: relative;
  text-align: center;
}

.full-width-image {
  width: 100%;
  height: auto;
  z-index: -1; //image behind other content
}

.first-row-header {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.card {
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 520px; //!important;
  margin-bottom: 40px;
}

.card-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.card-text {
  font-size: 1rem;
}

.headerPadding {
  margin-top: 70px;
  margin-bottom: 20px;
}

.marginImages {
  margin-bottom: 40px;
}

.image50x50 {
  max-width: 50px;
  max-height: 50px;
}
