.card {
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    height: auto;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.card-left {
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.card-header {
    background-color: transparent;
}
